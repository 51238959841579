// frontend/src/pages/EventDetailPage.js

import React, { useState, useEffect, useContext } from 'react';
import { Container, Typography, Button, Box, Grid, IconButton, TextField } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { db, storage } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { auth } from '../firebase';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { ThemeContext } from '../contexts/ThemeContext';
import { useTheme } from '@mui/material/styles';

const EventDetailPage = () => {
  const { id } = useParams();
  const { themeColor } = useContext(ThemeContext);
  const theme = useTheme();
  const [event, setEvent] = useState(null);
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editableData, setEditableData] = useState({
    experience: '',
    locations: '',
    dateTime: '',
    description: '',
    images: [],
  });
  const [newImages, setNewImages] = useState([]);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const docRef = doc(db, 'events', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const eventData = { id: docSnap.id, ...docSnap.data() };
          setEvent(eventData);
          setEditableData({
            experience: eventData.experience || '',
            locations: eventData.locations.join(', ') || '',
            dateTime: eventData.dateTime
              ? eventData.dateTime.toDate().toISOString().slice(0, 16)
              : '',
            description: eventData.description || '',
            images: eventData.images || [],
          });
        } else {
          console.log('No such document!');
          navigate('/explore');
        }
      } catch (error) {
        console.error('Error fetching event:', error);
      }
    };
    fetchEvent();
  }, [id, navigate]);

  const isCreator = auth.currentUser && auth.currentUser.uid === event?.creatorId;

  const handleBack = () => navigate('/explore');

  const handleEditClick = () => {
    if (isCreator) {
      setIsEditing(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableData({ ...editableData, [name]: value });
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setNewImages([...newImages, ...files]);
  };

  const handleRemoveExistingImage = async (index) => {
    const imageUrl = editableData.images[index];
    // Optionally, delete the image from Firebase Storage
    try {
      // Extract the storage path from the URL
      const storagePath = imageUrl.split('.com/')[1];
      const imageRef = ref(storage, storagePath);
      await deleteObject(imageRef);
    } catch (error) {
      console.error('Error deleting image from storage:', error);
    }

    const updatedImages = [...editableData.images];
    updatedImages.splice(index, 1);
    setEditableData({ ...editableData, images: updatedImages });
  };

  const handleRemoveNewImage = (index) => {
    const updatedNewImages = [...newImages];
    updatedNewImages.splice(index, 1);
    setNewImages(updatedNewImages);
  };

  const handlePrevImage = (e) => {
    e.stopPropagation();
    if (event.images && event.images.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? event.images.length - 1 : prevIndex - 1
      );
    }
  };

  const handleNextImage = (e) => {
    e.stopPropagation();
    if (event.images && event.images.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === event.images.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const handleSave = async () => {
    try {
      const eventRef = doc(db, 'events', id);
      const updatedData = {
        experience: editableData.experience,
        locations: editableData.locations.split(',').map(loc => loc.trim()),
        dateTime: editableData.dateTime ? new Date(editableData.dateTime) : null,
        description: editableData.description,
      };

      // Handle new image uploads
      if (newImages.length > 0) {
        const uploadedImages = await Promise.all(
          newImages.map(async (image) => {
            const imageRef = ref(storage, `event_images/${id}/${Date.now()}_${image.name}`);
            await uploadBytes(imageRef, image);
            const downloadURL = await getDownloadURL(imageRef);
            return downloadURL;
          })
        );
        updatedData.images = [...editableData.images, ...uploadedImages];
      } else {
        updatedData.images = editableData.images;
      }

      await updateDoc(eventRef, updatedData);
      alert('Event updated successfully!');
      setIsEditing(false);
      // Refresh event data
      const docSnap = await getDoc(eventRef);
      if (docSnap.exists()) {
        const eventData = { id: docSnap.id, ...docSnap.data() };
        setEvent(eventData);
        setEditableData({
          experience: eventData.experience || '',
          locations: eventData.locations.join(', ') || '',
          dateTime: eventData.dateTime
            ? eventData.dateTime.toDate().toISOString().slice(0, 16)
            : '',
          description: eventData.description || '',
          images: eventData.images || [],
        });
        setNewImages([]);
        setCurrentImageIndex(0);
      }
    } catch (error) {
      console.error('Error updating event:', error);
      alert('Failed to update the event. Please try again.');
    }
  };

  if (!event) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant="h5">Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4, position: 'relative' }}>
      {/* Back Button */}
      <IconButton onClick={handleBack} sx={{ mb: 2 }}>
        <ArrowBackIcon />
      </IconButton>

      {/* Event Title and Edit Button */}
      <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
        {isEditing ? (
          <TextField
            label="Experience"
            name="experience"
            value={editableData.experience}
            onChange={handleInputChange}
            variant="outlined"
            sx={{ flexGrow: 1, mr: 2, maxWidth: '100%' }}
            InputLabelProps={{ shrink: true }}
          />
        ) : (
          <Typography variant="h4" gutterBottom>
            {event.experience}
          </Typography>
        )}
        {isCreator && !isEditing && (
          <IconButton
            onClick={handleEditClick}
            sx={{
              backgroundColor: 'rgba(0,0,0,0.05)',
              '&:hover': { backgroundColor: 'rgba(0,0,0,0.1)' },
            }}
            aria-label="edit event"
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>

      <Grid container spacing={2}>
        {/* Event Details */}
        {isEditing ? (
          <Grid item xs={12} md={6}>
            <Box>
              {/* Locations */}
              <TextField
                label="Location(s)"
                name="locations"
                value={editableData.locations}
                onChange={handleInputChange}
                fullWidth
                sx={{ mb: 2 }}
                InputLabelProps={{ shrink: true }}
              />

              {/* Date & Time */}
              <TextField
                label="Date & Time"
                name="dateTime"
                type="datetime-local"
                value={editableData.dateTime}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                sx={{ mb: 2 }}
              />

              {/* Description */}
              <TextField
                label="Description"
                name="description"
                value={editableData.description}
                onChange={handleInputChange}
                multiline
                rows={4}
                fullWidth
                sx={{ mb: 2 }}
                InputLabelProps={{ shrink: true }}
              />

              {/* Save Button */}
              <Box sx={{ textAlign: 'center', mt: 3 }}>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: '#18453B', color: '#fff' }}
                  onClick={handleSave}
                  startIcon={<SaveIcon />}
                >
                  Save Changes
                </Button>
              </Box>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h6">Location(s):</Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {event.locations.join(', ')}
              </Typography>
              <Typography variant="h6">Date | Time:</Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {event.dateTime
                  ? `${new Date(event.dateTime.toDate()).toLocaleDateString()} | ${new Date(event.dateTime.toDate()).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`
                  : 'Date and time not available'}
              </Typography>
              <Typography variant="h6">Description:</Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {event.description || 'No description provided.'}
              </Typography>
              {/* Add to Calendar Button */}
              <Box sx={{ textAlign: 'center', mt: 3 }}>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: '#18453B', color: '#fff' }}
                  onClick={() => {
                    if (event.dateTime) {
                      const eventDate = new Date(event.dateTime.toDate());
                      const formattedDate = eventDate.toISOString().replace(/-|:|\.\d+/g, '');
                      const calendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
                        event.experience
                      )}&dates=${formattedDate}/${formattedDate}&details=${encodeURIComponent(
                        event.description || 'No description provided.'
                      )}&location=${encodeURIComponent(event.locations.join(', '))}`;
                      window.open(calendarUrl, '_blank');
                    }
                  }}
                  disabled={!event.dateTime}
                >
                  Add to Calendar
                </Button>
              </Box>
            </Box>
          </Grid>
        )}

        {/* Event Image with Navigation Arrows */}
        <Grid item xs={12} md={6}>
          <Box sx={{ position: 'relative', textAlign: 'center' }}>
            {isEditing ? (
              <Box sx={{ position: 'relative', display: 'inline-block' }}>
                {newImages.length > 0 ? (
                  <Box
                    component="img"
                    src={URL.createObjectURL(newImages[0])}
                    alt={`New Image`}
                    sx={{
                      width: '100%',
                      height: '200px', // Smaller image in edit mode
                      objectFit: 'cover',
                      borderRadius: '8px',
                      marginBottom: '8px',
                      transition: 'transform 0.3s, box-shadow 0.3s',
                      '&:hover': {
                        transform: 'scale(1.02)',
                        boxShadow: `0px 4px 8px ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : themeColor + '80'}`, // 80 for 50% opacity
                      },
                    }}
                  />
                ) : editableData.images[0] ? (
                  <Box
                    component="img"
                    src={editableData.images[0]}
                    alt={`Event Image`}
                    sx={{
                      width: '100%',
                      height: '200px', // Smaller image in edit mode
                      objectFit: 'cover',
                      borderRadius: '8px',
                      marginBottom: '8px',
                      transition: 'transform 0.3s, box-shadow 0.3s',
                      '&:hover': {
                        transform: 'scale(1.02)',
                        boxShadow: `0px 4px 8px ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : themeColor + '80'}`,
                      },
                    }}
                  />
                ) : (
                  <Typography variant="body1">No Images Available</Typography>
                )}

                {/* Add More Images Icon */}
                <IconButton
                  component="label"
                  sx={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '10px',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    color: 'white',
                    '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' },
                  }}
                  aria-label="add more images"
                >
                  <AddPhotoAlternateIcon />
                  <input
                    type="file"
                    hidden
                    multiple
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                </IconButton>
              </Box>
            ) : (
              <Box sx={{ position: 'relative' }}>
                <Box
                  component="img"
                  src={event.images[currentImageIndex]}
                  alt={`${event.experience} - Image ${currentImageIndex + 1}`}
                  sx={{
                    width: '100%',
                    height: '400px',
                    objectFit: 'cover',
                    borderRadius: '8px',
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      boxShadow: `0px 4px 8px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.7)' : themeColor + '80'}`,
                    },
                  }}
                />
                {event.images.length > 1 && (
                  <>
                    <IconButton
                      onClick={handlePrevImage}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '10px',
                        transform: 'translateY(-50%)',
                        backgroundColor: 'rgba(255,255,255,0.7)',
                        '&:hover': { backgroundColor: 'rgba(255,255,255,0.9)' },
                      }}
                      size="small"
                      aria-label="previous image"
                    >
                      <ArrowBackIosIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={handleNextImage}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        right: '10px',
                        transform: 'translateY(-50%)',
                        backgroundColor: 'rgba(255,255,255,0.7)',
                        '&:hover': { backgroundColor: 'rgba(255,255,255,0.9)' },
                      }}
                      size="small"
                      aria-label="next image"
                    >
                      <ArrowForwardIcon fontSize="small" />
                    </IconButton>
                  </>
                )}
              </Box>
            )}

            {/* Dot Indicators Positioned at Bottom of Image */}
            {(!isEditing && event.images.length > 1) && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  bottom: '12px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              >
                {event.images.map((_, idx) => (
                  <Box
                    key={idx}
                    sx={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor: currentImageIndex === idx ? 'white' : 'gray',
                      margin: '0 4px',
                      border: '1px solid #fff',
                    }}
                  />
                ))}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      {/* Existing Images (Edit Mode) */}
      {isEditing && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6">Existing Images</Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {editableData.images.map((img, index) => (
              <Grid item key={index}>
                <Box sx={{ position: 'relative' }}>
                  <Box
                    component="img"
                    src={img}
                    alt={`Event Image ${index + 1}`}
                    sx={{
                      width: '150px',
                      height: '100px',
                      objectFit: 'cover',
                      borderRadius: '8px',
                      transition: 'transform 0.3s, box-shadow 0.3s',
                      '&:hover': {
                        transform: 'scale(1.02)',
                        boxShadow: `0px 4px 8px ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : themeColor + '80'}`,
                      },
                    }}
                  />
                  <IconButton
                    onClick={() => handleRemoveExistingImage(index)}
                    sx={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      backgroundColor: 'rgba(255,255,255,0.7)',
                      '&:hover': { backgroundColor: 'rgba(255,255,255,0.9)' },
                    }}
                    size="small"
                    aria-label="remove image"
                  >
                    &times;
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* New Image Upload (Edit Mode) */}
      {isEditing && (
        <Box sx={{ mt: 4 }}>
          <Button
            variant="contained"
            component="label"
            startIcon={<AddPhotoAlternateIcon />}
            sx={{ mb: 2 }}
          >
            Add More Images
            <input
              type="file"
              hidden
              multiple
              accept="image/*"
              onChange={handleImageUpload}
            />
          </Button>

          {/* New Images Preview */}
          {newImages.length > 0 && (
            <Grid container spacing={2}>
              {newImages.map((img, index) => (
                <Grid item key={index}>
                  <Box sx={{ position: 'relative' }}>
                    <Box
                      component="img"
                      src={URL.createObjectURL(img)}
                      alt={`New Image ${index + 1}`}
                      sx={{
                        width: '150px',
                        height: '100px',
                        objectFit: 'cover',
                        borderRadius: '8px',
                        transition: 'transform 0.3s, box-shadow 0.3s',
                        '&:hover': {
                          transform: 'scale(1.02)',
                          boxShadow: `0px 4px 8px ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : themeColor + '80'}`,
                        },
                      }}
                    />
                    <IconButton
                      onClick={() => handleRemoveNewImage(index)}
                      sx={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        backgroundColor: 'rgba(255,255,255,0.7)',
                        '&:hover': { backgroundColor: 'rgba(255,255,255,0.9)' },
                      }}
                      size="small"
                      aria-label="remove new image"
                    >
                      &times;
                    </IconButton>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      )}
    </Container>
  );
};

export default EventDetailPage;