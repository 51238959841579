import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQPage = () => {
  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Frequently Asked Questions
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq1-content" id="faq1-header">
          <Typography variant="h6">What is SptGPT?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            SptGPT is an advanced AI-powered platform designed to assist users with a variety of tasks, from answering questions to providing recommendations.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq2-content" id="faq2-header">
          <Typography variant="h6">How do I change the theme color?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Navigate to the Settings page, where you can select a theme color using the color wheel or enter a specific hex code to personalize your experience.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq3-content" id="faq3-header">
          <Typography variant="h6">How can I copy my User ID?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Click on the help icon in the footer, then select your User ID from the popover. It will automatically be copied to your clipboard with a confirmation message.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq4-content" id="faq4-header">
          <Typography variant="h6">Where can I find the Terms of Use?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can access our Terms of Use by clicking the "Terms & Policies" link in the help popover located in the footer of the Settings page.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq5-content" id="faq5-header">
          <Typography variant="h6">Who can I contact for support?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            For any support inquiries, please visit our Contact Us page or reach out through the support options available in the application.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default FAQPage;