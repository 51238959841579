import React, { useContext } from 'react';
import { Container, Typography, Grid, Paper, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../contexts/ThemeContext';

const BookmarksPage = () => {
  const navigate = useNavigate();
  const { themeColor } = useContext(ThemeContext);
  const bookmarks = ['Bookmarked Event 1', 'Bookmarked Event 2', 'Bookmarked Event 3', 'Bookmarked Event 4', 'Bookmarked Event 5', 'Bookmarked Event 6'];

  return (
    <Container sx={{ padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom textAlign="center">Bookmarks</Typography>
      <Grid container spacing={2} sx={{ maxWidth: 800 }}>
        {bookmarks.map((bookmark, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              sx={{
                padding: 2,
                textAlign: 'center',
                borderRadius: 2,
                cursor: 'pointer',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': { transform: 'scale(1.02)', boxShadow: 3 },
                backgroundColor: themeColor === '#ffffff' ? '#f0f0f0' : '#ffffff',
              }}
              onClick={() => navigate(`/event/${index}`)}
            >
              <Typography variant="h6">{bookmark}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BookmarksPage;