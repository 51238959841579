// frontend/src/pages/LoginPage.js 

import React, { useState, useContext } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
  Divider,
} from '@mui/material';
import { FcGoogle } from 'react-icons/fc'; // Google Icon
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../contexts/ThemeContext';
import { auth } from '../firebase';
import {
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const LoginPage = () => {
  const [identifier, setIdentifier] = useState(''); // Email or Phone
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { themeColor } = useContext(ThemeContext);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  // Handle form submission
  const handleLogin = async (e) => {
    e.preventDefault();

    const phoneRegex = /^\+?[1-9]\d{1,14}$/; // E.164 format
    if (phoneRegex.test(identifier)) {
      setIsPhone(true);
      try {
        // Set up reCAPTCHA
        window.recaptchaVerifier = new auth.RecaptchaVerifier('recaptcha-container', {
          size: 'invisible',
          callback: () => {},
        });

        const result = await signInWithPhoneNumber(auth, identifier, window.recaptchaVerifier);
        setConfirmationResult(result);
        alert('Verification code sent to your phone.');
      } catch (error) {
        console.error('Phone sign-in error:', error);
        alert('Failed to send verification code. Please try again.');
      }
    } else {
      setIsPhone(false);
      try {
        await signInWithEmailAndPassword(auth, identifier, password);
        navigate('/profile'); // Redirect to profile page
      } catch (error) {
        console.error('Email sign-in error:', error);
        alert(error.message || 'Failed to sign in.');
      }
    }
  };

  // Handle verification code submission for phone sign-in
  const handleVerifyCode = async (e) => {
    e.preventDefault();
    if (!confirmationResult || !verificationCode) {
      alert('Please enter the verification code.');
      return;
    }

    try {
      await confirmationResult.confirm(verificationCode);
      setVerificationCode('');
      setConfirmationResult(null);
      navigate('/profile'); // Redirect to profile page
    } catch (error) {
      console.error('Verification code error:', error);
      alert('Invalid verification code. Please try again.');
    }
  };

  // Handle Google Sign-In
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate('/profile'); // Redirect to profile page
    } catch (error) {
      console.error('Google sign-in error:', error);
      alert('Failed to sign in with Google. Please try again.');
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        mt: 4,
        mb: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* Updated Top Text */}
      <Typography variant="h4" gutterBottom textAlign="center">
        Sign In
      </Typography>
      
      {/* Form Container with Original Width */}
      <Box
        component="form"
        onSubmit={isPhone ? handleVerifyCode : handleLogin}
        sx={{
          mt: 2,
          width: '50%', // Original width maintained
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {/* Email/Phone and Password Fields */}
        {!isPhone && (
          <TextField
            label="Email/Phone"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
            fullWidth
          />
        )}
        {isPhone && (
          <TextField
            label="Verification Code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            fullWidth
            placeholder="Enter verification code"
          />
        )}
        {!isPhone && (
          <TextField
            label="Password"
            type={isPasswordVisible ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={isPasswordVisible ? 'Hide password' : 'Show password'}
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        
        {/* Continue Button with Larger Text */}
        <Button
          variant="contained"
          type="submit"
          fullWidth
          sx={{
            backgroundColor: themeColor,
            color: '#fff',
            textTransform: 'none', // Prevent uppercase
            fontSize: '1.1rem', // Increase text size
            '&:hover': { backgroundColor: themeColor },
          }}
        >
          {isPhone ? 'Verify Code' : 'Continue'}
        </Button>
        
        {/* Divider Line */}
        {!isPhone && <Divider sx={{ my: 2 }}>OR</Divider>}
        
        {/* Sign in with Google Button */}
        {!isPhone && (
          <Button
            variant="outlined"
            onClick={handleGoogleSignIn}
            fullWidth
            sx={{
              borderColor: themeColor,
              color: themeColor,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textTransform: 'none', // Prevent uppercase
              '&:hover': {
                backgroundColor: '#f5f5f5',
                borderColor: themeColor,
              },
            }}
            startIcon={<FcGoogle />}
          >
            Sign in with Google
          </Button>
        )}
        
        {/* Stacked Buttons: Create Account & Forgot Password */}
        {!isPhone && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2, alignItems: 'center' }}>
            <Button
              variant="text"
              onClick={() => navigate('/create-account')}
              sx={{ color: themeColor, textTransform: 'none' }}
            >
              Create Account
            </Button>
            <Button
              variant="text"
              onClick={() => navigate('/forgot-password')}
              sx={{ color: themeColor, textTransform: 'none' }}
            >
              Forgot Password?
            </Button>
          </Box>
        )}
      </Box>
      
      {/* reCAPTCHA Container */}
      <Box id="recaptcha-container"></Box>
    </Container>
  );
};

export default LoginPage;