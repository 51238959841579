// src/pages/TermsPage.js

import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';

const TermsPage = () => {
  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Terms of Use
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to SptGPT! By accessing or using our services, you agree to be bound by these Terms of Use.
      </Typography>
      <Typography variant="h6" gutterBottom>
        1. Acceptance of Terms
      </Typography>
      <Typography variant="body1" paragraph>
        These terms govern your use of our website and services. Please read them carefully.
      </Typography>
      <Typography variant="h6" gutterBottom>
        2. Use of Services
      </Typography>
      <Typography variant="body1" paragraph>
        You agree to use the services only for lawful purposes and in a way that does not infringe the rights of others.
      </Typography>
      <Typography variant="h6" gutterBottom>
        3. Intellectual Property
      </Typography>
      <Typography variant="body1" paragraph>
        All content provided on this site is owned by or licensed to SptGPT. You may not reproduce or distribute it without permission.
      </Typography>
      <Typography variant="h6" gutterBottom>
        4. Privacy
      </Typography>
      <Typography variant="body1" paragraph>
        Our Privacy Policy explains how we handle your personal data. By using our services, you consent to the practices described in the policy.
      </Typography>
      <Typography variant="h6" gutterBottom>
        5. Termination
      </Typography>
      <Typography variant="body1" paragraph>
        We may terminate or suspend access to our services immediately, without prior notice, for conduct that we believe violates these Terms.
      </Typography>
      <Typography variant="h6" gutterBottom>
        6. Changes to Terms
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to modify these Terms at any time. Continued use of the services constitutes acceptance of the updated terms.
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions, please contact our <Link href="/faq">FAQ</Link> page.
      </Typography>
    </Container>
  );
};

export default TermsPage;