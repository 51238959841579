// NotificationsPage.jsx
import React, { useState, useContext } from 'react';
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Switch,
  TextField,
  Button,
  Snackbar,
  Alert,
  Drawer,
} from '@mui/material';
import { IoCloseOutline, IoSettingsSharp } from 'react-icons/io5';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme, useMediaQuery } from '@mui/material';
import { ThemeContext } from '../contexts/ThemeContext';

const NotificationsPage = () => {
  const { themeColor } = useContext(ThemeContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [notifications, setNotifications] = useState([
    'React Meetup confirmed',
    'Dashboard updated',
    'Password changed successfully',
  ]);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [settings, setSettings] = useState({
    mobileNotifications: false,
    emailNotifications: false,
    phoneNumber: '',
    isPhoneVerified: false,
    verificationCodePhone: '',
    showPhoneVerificationInput: false,
    expectedPhoneCode: '',
    email: '',
    isEmailVerified: false,
    verificationCodeEmail: '',
    showEmailVerificationInput: false,
    expectedEmailCode: '',
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const toggleSettingsDrawer = () => {
    setIsSettingsOpen(prev => !prev);
  };

  const handleToggle = (key) => (event) => {
    setSettings(prev => ({
      ...prev,
      [key]: event.target.checked,
      ...(key === 'mobileNotifications' && !event.target.checked
        ? {
            phoneNumber: '',
            isPhoneVerified: false,
            showPhoneVerificationInput: false,
            expectedPhoneCode: '',
          }
        : {}),
      ...(key === 'emailNotifications' && !event.target.checked
        ? {
            email: '',
            isEmailVerified: false,
            showEmailVerificationInput: false,
            expectedEmailCode: '',
          }
        : {}),
    }));
  };

  const handleSendVerificationCode = (type) => {
    if (type === 'phone') {
      const cleanedNumber = settings.phoneNumber.replace(/-/g, '');
      if (/^\d{10}$/.test(cleanedNumber)) {
        const code = Math.floor(100000 + Math.random() * 900000).toString();
        setSettings(prev => ({
          ...prev,
          showPhoneVerificationInput: true,
          expectedPhoneCode: code,
        }));
        setSnackbar({ open: true, message: 'Verification code sent to your phone!', severity: 'success' });
      } else {
        setSnackbar({ open: true, message: 'Enter a valid phone number.', severity: 'error' });
      }
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(settings.email)) {
        const code = Math.floor(100000 + Math.random() * 900000).toString();
        setSettings(prev => ({
          ...prev,
          showEmailVerificationInput: true,
          expectedEmailCode: code,
        }));
        setSnackbar({ open: true, message: 'Verification code sent to your email!', severity: 'success' });
      } else {
        setSnackbar({ open: true, message: 'Enter a valid email address.', severity: 'error' });
      }
    }
  };

  const handleVerifyCode = (type) => {
    if (type === 'phone') {
      if (settings.verificationCodePhone === settings.expectedPhoneCode) {
        setSettings(prev => ({
          ...prev,
          isPhoneVerified: true,
          showPhoneVerificationInput: false,
        }));
        setSnackbar({ open: true, message: 'Phone number verified!', severity: 'success' });
        setTimeout(() => setSettings(prev => ({ ...prev, isPhoneVerified: false })), 3000);
      } else {
        setSnackbar({ open: true, message: 'Invalid verification code.', severity: 'error' });
      }
    } else {
      if (settings.verificationCodeEmail === settings.expectedEmailCode) {
        setSettings(prev => ({
          ...prev,
          isEmailVerified: true,
          showEmailVerificationInput: false,
        }));
        setSnackbar({ open: true, message: 'Email address verified!', severity: 'success' });
        setTimeout(() => setSettings(prev => ({ ...prev, isEmailVerified: false })), 3000);
      } else {
        setSnackbar({ open: true, message: 'Invalid verification code.', severity: 'error' });
      }
    }
  };

  return (
    <Container
      sx={{
        mt: 4,
        paddingBottom: '80px',
        minHeight: 'calc(100vh - 80px)',
        position: 'relative',
        overflowX: 'hidden',
      }}
    >
      <Typography variant="h4" gutterBottom textAlign="center">
        Notifications
      </Typography>
      <IconButton
        sx={{
          position: 'absolute',
          top: 8,
          right: isMobile ? 10 : 20,
          color: themeColor,
          zIndex: 2,
        }}
        onClick={toggleSettingsDrawer}
        aria-label="Notification Settings"
      >
        <IoSettingsSharp size={24} />
      </IconButton>
      <Drawer
        anchor={isMobile ? 'left' : 'right'}
        open={isSettingsOpen}
        onClose={toggleSettingsDrawer}
        variant="temporary"
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: {
            width: isMobile ? '91%' : '20%',
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            ...(isMobile && {
              position: 'fixed',
              top: 0,
              left: 0,
              height: 'calc(100% - 107px)',
              marginBottom: '80px',
              boxShadow: theme.shadows[5],
              backgroundColor: theme.palette.background.paper,
              zIndex: theme.zIndex.drawer + 1,
            }),
          },
        }}
      >
        <Box display="flex" justifyContent="flex-end" width="100%">
          <IconButton onClick={toggleSettingsDrawer} aria-label="Close Settings">
            <IoCloseOutline size={24} />
          </IconButton>
        </Box>
        <Typography variant="h6" gutterBottom fontWeight="bold">
          Notification Settings
        </Typography>
        {['mobileNotifications', 'emailNotifications'].map((key) => (
          <Box key={key} width="100%">
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography variant="subtitle1">
                {key === 'mobileNotifications' ? 'Mobile Notifications' : 'Email Notifications'}
              </Typography>
              <Switch
                checked={settings[key]}
                onChange={handleToggle(key)}
                color="primary"
                inputProps={{ 'aria-label': `Toggle ${key === 'mobileNotifications' ? 'Mobile' : 'Email'} Notifications` }}
              />
            </Box>
            {settings[key] && (
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}>
                {key === 'mobileNotifications' ? (
                  <>
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      value={settings.phoneNumber}
                      onChange={(e) => setSettings(prev => ({ ...prev, phoneNumber: e.target.value }))}
                      placeholder="###-###-####"
                      inputProps={{ maxLength: 12, inputMode: 'numeric', pattern: '\\d{3}-\\d{3}-\\d{4}' }}
                      sx={{ mb: 2, width: '240px' }}
                    />
                    {!settings.isPhoneVerified && !settings.showPhoneVerificationInput && (
                      <Button
                        variant="contained"
                        onClick={() => handleSendVerificationCode('phone')}
                        sx={{
                          width: '240px',
                          mb: 2,
                          backgroundColor: themeColor,
                          '&:hover': { backgroundColor: theme.palette.primary.light },
                        }}
                      >
                        Send Code
                      </Button>
                    )}
                    {settings.showPhoneVerificationInput && (
                      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <TextField
                          label="Verification Code"
                          variant="outlined"
                          value={settings.verificationCodePhone}
                          onChange={(e) => setSettings(prev => ({ ...prev, verificationCodePhone: e.target.value }))}
                          placeholder="Enter code"
                          sx={{ mb: 2, width: '200px' }}
                        />
                        <Button
                          variant="contained"
                          onClick={() => handleVerifyCode('phone')}
                          sx={{
                            width: '220px',
                            backgroundColor: themeColor,
                            '&:hover': { backgroundColor: theme.palette.primary.light },
                          }}
                        >
                          Verify Code
                        </Button>
                      </Box>
                    )}
                    {settings.isPhoneVerified && (
                      <Box display="flex" alignItems="center" mt={2}>
                        <CheckCircleIcon color="success" />
                        <Typography variant="body2" color="success.main" ml={1}>
                          Phone number verified!
                        </Typography>
                      </Box>
                    )}
                  </>
                ) : (
                  <>
                    <TextField
                      label="Email Address"
                      variant="outlined"
                      value={settings.email}
                      onChange={(e) => setSettings(prev => ({ ...prev, email: e.target.value }))}
                      inputProps={{ maxLength: 50 }}
                      sx={{ mb: 2, width: '240px' }}
                    />
                    {!settings.isEmailVerified && !settings.showEmailVerificationInput && (
                      <Button
                        variant="contained"
                        onClick={() => handleSendVerificationCode('email')}
                        sx={{
                          width: '240px',
                          mb: 2,
                          backgroundColor: themeColor,
                          '&:hover': { backgroundColor: theme.palette.primary.light },
                        }}
                      >
                        Send Code
                      </Button>
                    )}
                    {settings.showEmailVerificationInput && (
                      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <TextField
                          label="Verification Code"
                          variant="outlined"
                          value={settings.verificationCodeEmail}
                          onChange={(e) => setSettings(prev => ({ ...prev, verificationCodeEmail: e.target.value }))}
                          placeholder="Enter code"
                          sx={{ mb: 2, width: '200px' }}
                        />
                        <Button
                          variant="contained"
                          onClick={() => handleVerifyCode('email')}
                          sx={{
                            width: '220px',
                            backgroundColor: themeColor,
                            '&:hover': { backgroundColor: theme.palette.primary.light },
                          }}
                        >
                          Verify Code
                        </Button>
                      </Box>
                    )}
                    {settings.isEmailVerified && (
                      <Box display="flex" alignItems="center" mt={2}>
                        <CheckCircleIcon color="success" />
                        <Typography variant="body2" color="success.main" ml={1}>
                          Email address verified!
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            )}
          </Box>
        ))}
      </Drawer>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="center"
        alignItems="flex-start"
        sx={{ mt: 4, gap: 4, overflowX: 'hidden' }}
      >
        <Box sx={{ flex: 1, width: '100%', maxWidth: '600px' }}>
          {notifications.length > 0 && (
            <List
              sx={{
                backgroundColor: '#fff',
                borderRadius: '8px',
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                maxHeight: '60vh',
                overflowY: 'auto',
                padding: 2,
                position: 'relative',
              }}
            >
              {notifications.map((notification, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      onClick={() => setNotifications(prev => prev.filter((_, i) => i !== index))}
                      aria-label="Delete Notification"
                      sx={{ zIndex: 1 }}
                    >
                      <IoCloseOutline size={20} />
                    </IconButton>
                  }
                  sx={{
                    backgroundColor: '#f9f9f9',
                    borderRadius: '8px',
                    mb: 1,
                    paddingX: 2,
                  }}
                >
                  <ListItemText primary={notification} primaryTypographyProps={{ style: { wordBreak: 'break-word' } }} />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default NotificationsPage;