import React, { useContext } from 'react';
import { Box, Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import ExploreIcon from '@mui/icons-material/Explore';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SchoolIcon from '@mui/icons-material/School';
import { ThemeContext } from '../contexts/ThemeContext';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const { themeColor } = useContext(ThemeContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  const pages = [
    { label: 'Classes', icon: <SchoolIcon sx={{ fontSize: 50 }} />, path: '/classes', external: false },
    { label: 'Explore', icon: <ExploreIcon sx={{ fontSize: 50 }} />, path: '/explore', external: false },
    { label: 'SIS', icon: <DashboardIcon sx={{ fontSize: 50 }} />, path: 'https://student.msu.edu/splash.html', external: true },
    { label: 'Profile', icon: <ManageAccountsIcon sx={{ fontSize: 50 }} />, path: '/profile', external: false },
  ];

  const handleNavigation = ({ path, external }) => external ? window.open(path, '_blank') : navigate(path);

  const handleKeyDown = (e, page) => {
    if (['Enter', ' '].includes(e.key)) {
      e.preventDefault();
      handleNavigation(page);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: isMobile ? 'flex-start' : 'center',
        padding: isMobile ? '40px 20px 20px 20px' : '20px',
        minHeight: '100vh',
        boxSizing: 'border-box',
        overflow: 'hidden',
      }}
    >
      <Grid container spacing={2} justifyContent="center" sx={{ maxWidth: isMobile ? '100%' : 500 }}>
        {pages.map(({ label, icon, path, external }, index) => (
          <Grid item xs={6} key={index}>
            <Paper
              elevation={3}
              onClick={() => handleNavigation({ path, external })}
              sx={{
                padding: '20px 10px',
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: '8px',
                backgroundColor: themeColor,
                color: 'white',
                height: '150px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
                },
                outline: 'none',
                '&:focus': {
                  boxShadow: `0 0 0 3px ${themeColor}`,
                },
              }}
              tabIndex={0}
              onKeyDown={(e) => handleKeyDown(e, { path, external })}
              aria-label={label}
            >
              {icon}
              <Typography variant="h6" sx={{ mt: 1 }}>
                {label}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HomePage;