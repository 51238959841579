// frontend/src/pages/CreateAccountPage.js

import React, { useState, useContext } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
  Divider,
} from '@mui/material';
import { FcGoogle } from 'react-icons/fc'; // Google Icon
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../contexts/ThemeContext';
import { auth, db } from '../firebase';
import {
  createUserWithEmailAndPassword,
  updateProfile,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const CreateAccountPage = () => {
  const [identifier, setIdentifier] = useState(''); // Email or Phone
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const { themeColor } = useContext(ThemeContext);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // Toggle password visibility
  const handleClickShowPassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Input validation
    if (!/\S+@\S+\.\S+/.test(identifier)) {
      alert('Please enter a valid email address or phone number.');
      return;
    }
    if (!/^(?=.*[!@#$%^&*]).{8,}$/.test(password)) {
      alert('Password must be at least 8 characters long and include a special character.');
      return;
    }
    if (!name.trim()) {
      alert('Please enter your name.');
      return;
    }

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, identifier, password);
      const user = userCredential.user;

      // Update user profile with display name
      await updateProfile(user, { displayName: name });

      // Optionally, add user to Firestore
      await db.collection('users').doc(user.uid).set({
        name,
        email: identifier,
        role: 'basic', // Default role
      });

      alert('Account created successfully. Please check your email for verification.');

      navigate('/profile'); // Redirect to profile or desired page
    } catch (error) {
      console.error('Error creating account:', error);
      alert(error.message || 'Error creating account.');
    }
  };

  // Handle Google Sign-Up
  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Optionally, add user to Firestore
      await db.collection('users').doc(user.uid).set({
        name: user.displayName,
        email: user.email,
        role: 'basic', // Default role
      });

      alert('Account created successfully with Google.');

      navigate('/profile'); // Redirect to profile page
    } catch (error) {
      console.error('Error during Google sign-up:', error);
      alert('Failed to create account with Google. Please try again.');
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        mt: 4,
        mb: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* Updated Top Text */}
      <Typography variant="h4" gutterBottom textAlign="center">
        Create an Account
      </Typography>

      {/* Form Container with Original Width */}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          mt: 2,
          width: '50%', // Original width maintained
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {/* Name Field */}
        <TextField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />

        {/* Email/Phone Field */}
        <TextField
          label="Email/Phone"
          value={identifier}
          onChange={(e) => setIdentifier(e.target.value)}
          fullWidth
        />

        {/* Password Field */}
        <TextField
          label="Password"
          type={isPasswordVisible ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={isPasswordVisible ? 'Hide password' : 'Show password'}
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {/* Continue Button */}
        <Button
          variant="contained"
          type="submit"
          fullWidth
          sx={{
            backgroundColor: themeColor,
            color: '#fff',
            textTransform: 'none', // Prevent uppercase
            fontSize: '1.1rem', // Increase text size
            '&:hover': { backgroundColor: themeColor },
          }}
        >
          Continue
        </Button>

        {/* "Already have an account? Login" Text */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
          <Typography variant="body2" sx={{ mr: 0.5 }}>
            Already have an account?
          </Typography>
          <Button
            variant="text"
            onClick={() => navigate('/login')}
            sx={{ color: themeColor, textTransform: 'none', padding: 0, minWidth: 'auto' }}
          >
            Login
          </Button>
        </Box>

        {/* Divider */}
        <Divider sx={{ my: 2 }}>OR</Divider>

        {/* Continue with Google Button */}
        <Button
          variant="outlined"
          onClick={handleGoogleSignUp}
          fullWidth
          sx={{
            borderColor: themeColor,
            color: themeColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'none', // Prevent uppercase
            fontWeight: 'bold', // Bolden the text
            '&:hover': {
              backgroundColor: '#f5f5f5',
              borderColor: themeColor,
            },
          }}
          startIcon={<FcGoogle />}
        >
          Continue with Google
        </Button>
      </Box>

      {/* reCAPTCHA Container */}
      <Box id="recaptcha-container"></Box>
    </Container>
  );
};

export default CreateAccountPage;