// frontend/src/pages/CreatePage.js

import React, { useState, useContext, useEffect } from 'react';
import {
  TextField,
  Button,
  Container,
  Grid,
  Autocomplete,
  Box,
  Alert,
  IconButton,
  Chip,
  useMediaQuery,
  GlobalStyles,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../contexts/ThemeContext';
import { useTheme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { auth, db, storage } from '../firebase';
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  Timestamp,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const CreatePage = () => {
  const [experience, setExperience] = useState('');
  const [locations, setLocations] = useState([]);
  const [inputLocation, setInputLocation] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null); // Added success state
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { themeColor } = useContext(ThemeContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const allLocations = [
    'Wilson Hall',
    'Wonders Hall'
  ];

  const isFormComplete =
    experience.trim() &&
    locations.length > 0 &&
    date.trim() &&
    time.trim() &&
    images.length > 0;

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (images.length + files.length > 3) {
      setError('You can upload up to 3 images.');
      return;
    }
    const newImages = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
      id: `${file.name}-${Date.now()}-${Math.random()}`,
    }));
    setImages((prev) => [...prev, ...newImages]);
    setError(null);
  };

  const handleImageRemove = (id) => {
    setImages((prev) => {
      const updated = prev.filter((img) => img.id !== id);
      const removed = prev.find((img) => img.id === id);
      if (removed) URL.revokeObjectURL(removed.preview);
      return updated;
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const reordered = Array.from(images);
    const [moved] = reordered.splice(result.source.index, 1);
    reordered.splice(result.destination.index, 0, moved);
    setImages(reordered);
  };

  const checkEventLimit = async () => {
    const user = auth.currentUser;
    if (!user) return 0;

    const startOfMonth = new Date();
    startOfMonth.setDate(1);
    startOfMonth.setHours(0, 0, 0, 0);
    const endOfMonth = new Date(startOfMonth);
    endOfMonth.setMonth(endOfMonth.getMonth() + 1);

    const q = query(
      collection(db, 'events'),
      where('creatorId', '==', user.uid),
      where('createdAt', '>=', Timestamp.fromDate(startOfMonth)),
      where('createdAt', '<', Timestamp.fromDate(endOfMonth))
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.size;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormComplete) {
      setError(
        'Please fill in all fields, select at least one location, and upload at least one image.'
      );
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null); // Reset success state

    try {
      const user = auth.currentUser;
      if (!user) {
        setError('You must be logged in to create an event.');
        setLoading(false);
        return;
      }

      // Check event limit
      const eventCount = await checkEventLimit();
      if (eventCount >= 3) {
        setError('You have reached the limit of 3 events for this month.');
        setLoading(false);
        return;
      }

      // Upload images to Firebase Storage and get URLs
      const imageUrls = [];
      for (const img of images) {
        try {
          const storageRef = ref(
            storage,
            `eventImages/${user.uid}/${Date.now()}_${img.file.name}`
          );
          const snapshot = await uploadBytes(storageRef, img.file);
          const url = await getDownloadURL(snapshot.ref);
          imageUrls.push(url);
        } catch (uploadError) {
          console.error(`Error uploading image ${img.file.name}:`, uploadError);
          throw uploadError; // This will be caught by the main try-catch
        }
      }

      // Combine date and time into a single Timestamp
      const dateTimeString = `${date}T${time}:00`;
      const eventDateTime = new Date(dateTimeString);
      const timestamp = Timestamp.fromDate(eventDateTime);
      const eventData = {
        experience,
        locations,
        dateTime: timestamp, // Combined date and time
        images: imageUrls,
        creatorId: user.uid,
        creatorEmail: user.email,
        createdAt: Timestamp.now(),
      };

      // Add event to Firestore
      console.log('Event Data:', eventData);
      const docRef = await addDoc(collection(db, 'events'), eventData);

      setSuccess('Event created successfully!');
      setExperience('');
      setLocations([]);
      setDate('');
      setTime('');
      setImages([]);
      // Optionally, navigate to ExplorePage after creation
      navigate('/explore');
    } catch (err) {
      console.error('Error creating event:', err);
      setError(`An error occurred while creating the event: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => setSuccess(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [success]);

  useEffect(() => {
    return () => {
      images.forEach((img) => URL.revokeObjectURL(img.preview));
    };
  }, [images]);

  const customFilterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option,
  });

  // Compute filtered options based on input
  const filteredOptions =
    inputLocation.length > 0
      ? allLocations.filter((loc) =>
          loc.toLowerCase().startsWith(inputLocation.toLowerCase())
        )
      : [];

  // Determine if Autocomplete should be open
  useEffect(() => {
    if (inputLocation.length > 0 && filteredOptions.length > 0) {
      setOpenAutocomplete(true);
    } else {
      setOpenAutocomplete(false);
    }
  }, [inputLocation, filteredOptions]);

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        py: isMobile ? 2 : 3,
        px: isMobile ? 1 : 3,
        position: 'relative',
        overflow: 'visible',
        mt: isMobile ? 2 : 4,
      }}
    >
      <GlobalStyles
        styles={{
          '*::-webkit-scrollbar': {
            width: '12px',
          },
          '*::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '6px',
          },
          '*::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
            borderRadius: '6px',
          },
          '*::-webkit-scrollbar-thumb:hover': {
            background: theme.palette.primary.dark,
          },
        }}
      />
      <Box
        component="form"
        onSubmit={handleSubmit}
        id="create-form"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 0,
        }}
        noValidate // Disable native browser validation
      >
        <Grid container spacing={isMobile ? 1 : 2} justifyContent="center">
          {!isMobile && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  gap: 2,
                  width: '90%',
                  borderRadius: '60px',
                  border: `1px solid ${theme.palette.primary.main}`,
                  padding: 3,
                  mt: -4,
                  mx: 'auto',
                  overflow: 'visible',
                }}
              >
                {/* Experience Field */}
                <TextField
                  label="Experience"
                  value={experience}
                  onChange={(e) => {
                    if (e.target.value.length <= 100)
                      setExperience(e.target.value);
                  }}
                  fullWidth
                  inputProps={{
                    maxLength: 100,
                    autoComplete: 'off',
                    style: { textAlign: 'center' },
                  }}
                  variant="outlined"
                  sx={{
                    width: '25%',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '60px',
                    },
                  }}
                  InputLabelProps={{ required: false }} // Remove asterisk
                />

                {/* Location Autocomplete */}
                <Autocomplete
                  multiple
                  options={filteredOptions}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => {
                    if (newValue.length <= 10) {
                      setLocations(newValue);
                      setError(null);
                    } else {
                      setError('You can select up to 10 locations.');
                    }
                  }}
                  inputValue={inputLocation}
                  onInputChange={(event, newInputValue) =>
                    setInputLocation(newInputValue)
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option}
                        {...getTagProps({ index })}
                        key={option + index}
                        sx={{
                          margin: '6px',
                        }}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Location"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                        style: { textAlign: 'center' },
                      }}
                      InputLabelProps={{ required: false }} // Remove asterisk
                    />
                  )}
                  filterOptions={(options, state) => {
                    const filtered = customFilterOptions(options, state);
                    return isMobile ? filtered.slice(0, 1) : filtered.slice(0, 2);
                  }}
                  sx={{
                    width: '45%',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '60px',
                    },
                  }}
                  disableClearable
                  noOptionsText=""
                  open={openAutocomplete}
                />

                {/* Date Field */}
                <TextField
                  label="Date"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  fullWidth
                  InputLabelProps={{ shrink: true, required: false }} // Remove asterisk
                  inputProps={{
                    min: new Date().toISOString().split('T')[0],
                    max: '2024-12-31',
                    autoComplete: 'off',
                    style: { textAlign: 'center' },
                  }}
                  variant="outlined"
                  sx={{
                    width: '23%',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      '& fieldset': {
                        borderColor: theme.palette.divider,
                      },
                      '&:hover fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                    },
                  }}
                />

                {/* Time Field */}
                <TextField
                  label="Time"
                  type="time"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  fullWidth
                  InputLabelProps={{ shrink: true, required: false }} // Remove asterisk
                  inputProps={{
                    step: 300,
                    autoComplete: 'off',
                    style: { textAlign: 'center' },
                  }}
                  variant="outlined"
                  sx={{
                    width: '19.75%',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '4px',
                      '& fieldset': {
                        borderColor: theme.palette.divider,
                      },
                      '&:hover fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                    },
                  }}
                />

                {/* Image Upload Button */}
                <IconButton
                  component="label"
                  aria-label="upload images"
                  sx={{
                    color: theme.palette.primary.main,
                    width: '5%',
                    padding: '6px',
                    borderRadius: '50%',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <AttachFileIcon fontSize="large" />
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    multiple
                    onChange={handleImageChange}
                  />
                </IconButton>
              </Box>
            </Grid>
          )}

          {isMobile && (
            <>
              {/* Experience Field */}
              <Grid item xs={12}>
                <TextField
                  label="Experience"
                  value={experience}
                  onChange={(e) => {
                    if (e.target.value.length <= 100)
                      setExperience(e.target.value);
                  }}
                  fullWidth
                  inputProps={{
                    maxLength: 100,
                    autoComplete: 'off',
                    style: { textAlign: 'center' },
                  }}
                  variant="outlined"
                  sx={{
                    width: '100%',
                  }}
                  InputLabelProps={{ required: false }} // Remove asterisk
                />
              </Grid>

              {/* Location Autocomplete */}
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={filteredOptions}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => {
                    if (newValue.length <= 10) {
                      setLocations(newValue);
                      setError(null);
                    } else {
                      setError('You can select up to 10 locations.');
                    }
                  }}
                  inputValue={inputLocation}
                  onInputChange={(event, newInputValue) =>
                    setInputLocation(newInputValue)
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option}
                        {...getTagProps({ index })}
                        key={option + index}
                        sx={{
                          margin: '6px',
                        }}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Location"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                        style: { textAlign: 'center' },
                      }}
                      InputLabelProps={{ required: false }} // Remove asterisk
                    />
                  )}
                  filterOptions={(options, state) => {
                    const filtered = customFilterOptions(options, state);
                    return filtered.slice(0, 1); // Limit to 1 suggestion on mobile
                  }}
                  sx={{ width: '100%' }}
                  disableClearable
                  noOptionsText=""
                  open={openAutocomplete}
                />
              </Grid>

              {/* Date and Time Fields */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 1,
                    width: '100%',
                  }}
                >
                  <TextField
                    label="Date"
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    fullWidth
                    InputLabelProps={{ shrink: true, required: false }} // Remove asterisk
                    inputProps={{
                      min: new Date().toISOString().split('T')[0],
                      max: '2024-12-31',
                      autoComplete: 'off',
                      style: { textAlign: 'center' },
                    }}
                    variant="outlined"
                    sx={{
                      width: '40%',
                    }}
                  />

                  <TextField
                    label="Time"
                    type="time"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    fullWidth
                    InputLabelProps={{ shrink: true, required: false }} // Remove asterisk
                    inputProps={{
                      step: 300,
                      autoComplete: 'off',
                      style: { textAlign: 'center' },
                    }}
                    variant="outlined"
                    sx={{
                      width: '40%',
                    }}
                  />

                  {/* Image Upload Button */}
                  <IconButton
                    component="label"
                    aria-label="upload images"
                    sx={{
                      color: theme.palette.primary.main,
                      width: '20%',
                      padding: '6px',
                      borderRadius: '50%',
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    <AttachFileIcon fontSize="large" />
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      multiple
                      onChange={handleImageChange}
                    />
                  </IconButton>
                </Box>
              </Grid>
            </>
          )}

          {/* Image Previews and Drag & Drop */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                gap: isMobile ? 1 : 2,
                mt: isMobile ? 2 : 7,
              }}
            >
              {images.length > 0 && (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="images" direction="horizontal">
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        sx={{
                          display: 'flex',
                          gap: 2,
                          overflowX: 'auto',
                          width: '100%',
                          maxWidth: isMobile ? 360 : 500,
                          padding: 1,
                          border: `2px dashed ${theme.palette.primary.main}`,
                          borderRadius: 2,
                          justifyContent: 'center',
                        }}
                      >
                        {images.map((image, index) => (
                          <Draggable
                            key={image.id}
                            draggableId={image.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={{
                                  position: 'relative',
                                  width: isMobile ? 140 : 160,
                                  height: isMobile ? 140 : 160,
                                  borderRadius: '16px',
                                  overflow: 'hidden',
                                  boxShadow: snapshot.isDragging
                                    ? 3
                                    : 1,
                                  cursor: 'grab',
                                  backgroundColor: '#f0f0f0',
                                  border: `1px solid ${theme.palette.divider}`,
                                }}
                              >
                                <Box
                                  component="img"
                                  src={image.preview}
                                  alt={`Upload Preview ${index + 1}`}
                                  sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                />
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleImageRemove(image.id)
                                  }
                                  sx={{
                                    position: 'absolute',
                                    top: 4,
                                    right: 4,
                                    backgroundColor:
                                      'rgba(50, 50, 50, 0.6)',
                                    color: '#fff',
                                    '&:hover': {
                                      backgroundColor:
                                        'rgba(50, 50, 50, 0.8)',
                                    },
                                  }}
                                >
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              </Box>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </DragDropContext>
              )}

              {/* Display Error Message */}
              {error && (
                <Alert
                  severity="error"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '50px',
                    padding: '10px 20px',
                    maxWidth: 'fit-content',
                    mt: 2,
                  }}
                >
                  {error}
                </Alert>
              )}

              {/* Display Success Message */}
              {success && (
                <Alert
                  severity="success"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '50px',
                    padding: '10px 20px',
                    maxWidth: 'fit-content',
                    mt: 2,
                  }}
                >
                  {success}
                </Alert>
              )}
            </Box>
          </Grid>

          {/* Create Button */}
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                mt: isMobile ? 2 : 2,
              }}
            >
              <Button
                variant="contained"
                type="submit"
                aria-label="create event"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: '#fff',
                  borderRadius: '20px',
                  fontSize: '1.5rem',
                  textTransform: 'none',
                  width: '180px',
                  height: 50,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Create'
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default CreatePage;