// frontend/src/App.js

import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LeftNavBar from './components/LeftNavBar';
import MobileFooter from './components/MobileFooter';
import HomePage from './pages/HomePage';
import ExplorePage from './pages/ExplorePage';
import SearchPage from './pages/SearchPage';
import NotificationsPage from './pages/NotificationsPage';
import BookmarksPage from './pages/BookmarksPage';
import UpgradePage from './pages/UpgradePage';
import CreatePage from './pages/CreatePage';
import ProfilePage from './pages/ProfilePage';
import SettingsPage from './pages/SettingsPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import CreateAccountPage from './pages/CreateAccountPage';
import LoginPage from './pages/LoginPage'; // Ensure you have a LoginPage
import TermsPage from './pages/TermsPage';
import FAQPage from './pages/FAQPage';
import EventDetailPage from './pages/EventDetailPage';
import ProtectedRoute from './components/ProtectedRoute';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/material';

const App = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => setDrawerOpen(!isDrawerOpen);

  // Detect if the current view is mobile
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Router>
      {/* Conditionally render LeftNavBar or MobileFooter */}
      {!isMobile && (
        <LeftNavBar isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
      )}
      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingLeft: !isMobile ? (isDrawerOpen ? '240px' : '80px') : 0, // Adjust padding for desktop
          paddingBottom: isMobile ? '56px' : 0, // Adjust padding for mobile footer (default height 56px)
          transition: 'padding-left 0.3s, padding-bottom 0.3s',
          overflow: 'auto',
          minHeight: '100vh',
        }}
      >
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/explore" element={<ExplorePage />} />
          <Route path="/events/:id" element={<EventDetailPage />} /> {/* Corrected Route */}
          <Route path="/search" element={<SearchPage />} />
          <Route path="/notifications" element={<NotificationsPage />} />
          <Route path="/bookmarks" element={<BookmarksPage />} />
          <Route path="/upgrade" element={<UpgradePage />} />
          <Route
            path="/create"
            element={
              <ProtectedRoute>
                <CreatePage />
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/create-account" element={<CreateAccountPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          
          />
          {/* Remove ProtectedRoute for SettingsPage to make it universally accessible */}
          <Route
            path="/settings"
            element={<SettingsPage isDrawerOpen={isDrawerOpen} />}
          />
          {/* Add other routes as needed */}
        </Routes>
      </Box>
      {/* Render MobileFooter only on mobile */}
      {isMobile && <MobileFooter />}
    </Router>
  );
};

export default App;