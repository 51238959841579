import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  InputBase,
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
  Collapse,
  Avatar,
} from '@mui/material';
import { IoSearchOutline, IoCloseOutline, IoSearchSharp } from 'react-icons/io5';
import { styled } from '@mui/material/styles';
import { ThemeContext } from '../contexts/ThemeContext';
import { useNavigate } from 'react-router-dom';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  marginLeft: theme.spacing(1),
  flex: 1,
  '&::placeholder': {
    color: '#aaa',
    opacity: 1,
  },
}));

const SearchPage = () => {
  const { themeColor } = useContext(ThemeContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const searchInputRef = useRef(null);
  const [showSearchFor, setShowSearchFor] = useState(false);
  const [prevQueryLength, setPrevQueryLength] = useState(0);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const navigate = useNavigate();
  const suggestionRefs = useRef([]);

  useEffect(() => {
    searchInputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (prevQueryLength === 1 && searchQuery.length === 0) {
      setShowSearchFor(true);
    } else if (searchQuery.length > 0) {
      setShowSearchFor(false);
    }
    setPrevQueryLength(searchQuery.length);
  }, [searchQuery, prevQueryLength]);

  const fetchSuggestions = (query) => {
    const experiences = ['React Conference 2024', 'JavaScript Meetup', 'Fullstack Workshop'];
    const people = ['John Doe', 'Jane Smith', 'Alice Johnson'];
    const generalData = [
      'React',
      'JavaScript',
      'Material-UI',
      'Frontend Development',
      'Backend Development',
      'Fullstack',
      'CSS',
      'HTML',
      'Node.js',
      'Express',
      'MongoDB',
      'TypeScript',
      'GraphQL',
      'Redux',
      'Next.js',
      'Gatsby',
      'Vue.js',
      'Angular',
      'Svelte',
      'Tailwind CSS',
    ];

    const lowerCaseQuery = query.toLowerCase();

    const filteredExperiences = experiences.filter((item) =>
      item.toLowerCase().includes(lowerCaseQuery)
    );
    const filteredPeople = people.filter((item) =>
      item.toLowerCase().includes(lowerCaseQuery)
    );
    const filteredGeneral = generalData.filter((item) =>
      item.toLowerCase().includes(lowerCaseQuery)
    );

    return [
      ...filteredPeople.map((person) => ({ type: 'people', value: person })),
      ...filteredExperiences.map((exp) => ({ type: 'experiences', value: exp })),
      ...filteredGeneral.map((gen) => ({ type: 'general', value: gen })),
    ];
  };

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query.length > 0) {
      const results = fetchSuggestions(query);
      setSuggestions(results);
      setDropdownOpen(results.length > 0);
      setHighlightedIndex(-1);
    } else {
      setSuggestions([]);
      setDropdownOpen(false);
      setHighlightedIndex(-1);
    }
  };

  const handleSearchIconClick = () => {
    searchInputRef.current?.focus();
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchQuery('');
    setDropdownOpen(false);
    setShowSearchFor(false);
    setHighlightedIndex(-1);
    switch (suggestion.type) {
      case 'people':
        navigate(`/profile/${encodeURIComponent(suggestion.value)}`);
        break;
      case 'experiences':
        navigate(`/explore/${encodeURIComponent(suggestion.value)}`);
        break;
      case 'general':
        navigate(`/search/${encodeURIComponent(suggestion.value)}`);
        break;
      default:
        navigate(`/search/${encodeURIComponent(suggestion.value)}`);
    }
  };

  const handleClickAway = () => {
    setDropdownOpen(false);
  };

  const handleKeyDown = (e) => {
    if (!dropdownOpen) return;
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setHighlightedIndex((prev) => (prev < suggestions.length - 1 ? prev + 1 : 0));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setHighlightedIndex((prev) => (prev > 0 ? prev - 1 : suggestions.length - 1));
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (highlightedIndex >= 0 && highlightedIndex < suggestions.length) {
        handleSuggestionClick(suggestions[highlightedIndex]);
      }
    }
  };

  useEffect(() => {
    if (highlightedIndex >= 0 && suggestionRefs.current[highlightedIndex]) {
      suggestionRefs.current[highlightedIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [highlightedIndex]);

  return (
    <Container
      sx={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        minHeight: '100vh',
      }}
    >
      <Box
        component="form"
        onSubmit={(e) => e.preventDefault()}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          maxWidth: '400px',
          marginBottom: '10px',
          position: 'relative',
        }}
      >
        <Paper
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '2px 10px',
            borderRadius: '25px',
            backgroundColor: '#f0f0f0',
            width: '100%',
            border: '1px solid #ccc',
            height: '40px',
            transition: 'border-color 0.3s',
            '&:focus-within': {
              borderColor: themeColor,
            },
          }}
        >
          <IconButton
            sx={{
              padding: '10px',
              color: themeColor,
            }}
            onClick={handleSearchIconClick}
            aria-label="search"
          >
            {searchQuery.length > 0 ? <IoSearchSharp size={20} /> : <IoSearchOutline size={20} />}
          </IconButton>
          <StyledInputBase
            placeholder={searchQuery === '' ? 'Search' : ''}
            value={searchQuery}
            inputProps={{ maxLength: 100, autoComplete: 'off' }}
            onChange={handleInputChange}
            inputRef={searchInputRef}
            onKeyDown={handleKeyDown}
          />
          {searchQuery.length > 0 && (
            <IconButton
              sx={{ padding: '10px' }}
              onClick={() => setSearchQuery('')}
              aria-label="clear search"
            >
              <IoCloseOutline size={18} />
            </IconButton>
          )}
        </Paper>
        <Collapse in={showSearchFor && !dropdownOpen}>
          <Box
            sx={{
              position: 'absolute',
              top: '37px',
              left: 45,
              width: '100%',
              maxWidth: '275px',
              backgroundColor: 'white',
              border: '1px solid #ccc',
              borderRadius: '12px',
              boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
              padding: '10px',
              zIndex: 1,
              marginTop: '10px',
            }}
          >
            <Typography variant="subtitle2" color="#555" align="center">
              Search for classes, experiences, or people
            </Typography>
          </Box>
        </Collapse>
      </Box>
      {dropdownOpen && suggestions.length > 0 && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper
            sx={{
              width: '100%',
              maxWidth: '400px',
              borderRadius: '8px',
              boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
              maxHeight: '400px',
              overflowY: 'auto',
              marginBottom: '20px',
              zIndex: 1,
            }}
          >
            <List>
              {suggestions.map((suggestion, index) => (
                <ListItem
                  button
                  key={`${suggestion.type}-${index}`}
                  onClick={() => handleSuggestionClick(suggestion)}
                  selected={index === highlightedIndex}
                  ref={(el) => (suggestionRefs.current[index] = el)}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: index === highlightedIndex ? '#e0e0e0' : 'inherit',
                    color: index === highlightedIndex ? 'black' : 'inherit',
                    '&:hover': {
                      backgroundColor: '#e0e0e0',
                      color: 'black',
                    },
                  }}
                >
                  {suggestion.type === 'people' && (
                    <Avatar sx={{ marginRight: '10px' }}>
                      {suggestion.value.charAt(0)}
                    </Avatar>
                  )}
                  <ListItemText primary={suggestion.value} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </ClickAwayListener>
      )}
    </Container>
  );
};

export default SearchPage;