import React, { useEffect, useState, useContext } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper, Tooltip } from '@mui/material';
import { MdExplore, MdOutlineExplore } from 'react-icons/md';
import { IoHomeOutline, IoHome, IoNotificationsOutline, IoNotifications } from 'react-icons/io5';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser as faUserSolid } from '@fortawesome/free-solid-svg-icons';
import { faUser as faUserRegular } from '@fortawesome/free-regular-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { ThemeContext } from '../contexts/ThemeContext';

const MobileFooter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { themeColor } = useContext(ThemeContext);

  const getValue = (pathname) => {
    const mapping = {
      '/home': 'home',
      '/explore': 'explore',
      '/create': 'create',
      '/notifications': 'notifications',
      '/profile': 'profile',
    };
    return Object.keys(mapping).find(key => pathname.startsWith(key)) ? mapping[Object.keys(mapping).find(key => pathname.startsWith(key))] : 'home';
  };

  const [value, setValue] = useState(getValue(location.pathname));

  useEffect(() => {
    setValue(getValue(location.pathname));
  }, [location.pathname]);

  const actions = [
    {
      label: 'Home',
      value: 'home',
      icons: [<IoHomeOutline size={28} />, <IoHome size={28} />],
    },
    {
      label: 'Explore',
      value: 'explore',
      icons: [<MdOutlineExplore size={28} />, <MdExplore size={28} />],
    },
    {
      label: 'Create',
      value: 'create',
      icons: [<AddCircleOutlineIcon sx={{ fontSize: 46 }} />, <AddCircleIcon sx={{ fontSize: 46 }} />],
    },
    {
      label: 'Notifications',
      value: 'notifications',
      icons: [<IoNotificationsOutline size={28} />, <IoNotifications size={28} />],
    },
    {
      label: 'Profile',
      value: 'profile',
      icons: [
        <FontAwesomeIcon icon={faUserRegular} size="lg" />,
        <FontAwesomeIcon icon={faUserSolid} size="lg" />
      ],
    },
  ];

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          navigate(`/${newValue}`);
        }}
        showLabels={false}
        sx={{
          backgroundColor: 'transparent',
        }}
      >
        {actions.map(({ label, value: actionValue, icons }) => (
          <Tooltip key={actionValue} title={label} placement="top">
            <BottomNavigationAction
              value={actionValue}
              icon={icons ? (value === actionValue ? icons[1] : icons[0]) : null}
              sx={{
                minWidth: 0,
                maxWidth: 'none',
                color: themeColor,
                '&.Mui-selected': {
                  color: themeColor,
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                },
                '&:hover': {
                  color: themeColor,
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                },
                '&.MuiBottomNavigationAction-root:focus': {
                  outline: 'none',
                  boxShadow: 'none',
                },
              }}
              disableRipple
            />
          </Tooltip>
        ))}
      </BottomNavigation>
    </Paper>
  );
};

export default MobileFooter;