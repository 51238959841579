// frontend/src/pages/ForgotPasswordPage.js

import React, { useState, useContext } from 'react';
import { Container, Typography, TextField, Button, Box } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { ThemeContext } from '../contexts/ThemeContext';
import { auth } from '../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { themeColor } = useContext(ThemeContext);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Input validation
    if (!/\S+@\S+\.\S+/.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }

    try {
      // Send password reset email
      await sendPasswordResetEmail(auth, email);
      alert('Password reset link has been sent to your email.');
      navigate('/login'); // Redirect to login or desired page
    } catch (error) {
      console.error('Error sending password reset email:', error);
      alert(error.message || 'Error sending password reset link.');
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        mt: 4,
        mb: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4" gutterBottom textAlign="center">
        Forgot Password
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          mt: 2,
          width: '50%', // Original width maintained
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
        />
        <Button
          variant="contained"
          type="submit"
          fullWidth
          sx={{
            backgroundColor: themeColor,
            color: '#fff',
            textTransform: 'none', // Prevent uppercase
            fontSize: '1.1rem', // Increase text size
            '&:hover': { backgroundColor: themeColor },
          }}
        >
          Send
        </Button>
      </Box>
    </Container>
  );
};

export default ForgotPasswordPage;