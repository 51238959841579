// src/pages/SettingsPage.js

import React, { useContext, useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  IconButton,
  Popover,
  Link,
  Snackbar,
  Alert,
} from '@mui/material';
import { ThemeContext } from '../contexts/ThemeContext';
import { FaQuestionCircle } from 'react-icons/fa';
import { styled } from '@mui/material/styles';

// Define the list of predefined colors with Grey replacing White
const predefinedColors = [
  { name: 'MSU Dark Green', hex: '#154734' },
  { name: 'Light Green', hex: '#8BC34A' },
  { name: 'Dark Blue', hex: '#0D47A1' },
  { name: 'Light Blue', hex: '#03A9F4' },
  { name: 'Red', hex: '#F44336' },
  { name: 'Yellow', hex: '#FFEB3B' },
  { name: 'Orange', hex: '#FF9800' },
  { name: 'Grey', hex: '#808080' }, // Replaced White with Grey
  { name: 'Black', hex: '#000000' },
];

// Styled component for SptGPT with hover effect
const SptGPT = styled('span')(({ themeColor }) => ({
  display: 'inline-block',
  cursor: 'pointer',
  color: themeColor,
  transition: 'transform 0.2s, color 0.2s',
  userSelect: 'none', // Prevent text highlighting
  '&:hover': {
    transform: 'scale(1.05)',
    color: themeColor, // Maintain color
  },
}));

const SettingsPage = ({ isDrawerOpen }) => { // Receive isDrawerOpen as a prop
  const { themeColor, setThemeColor } = useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // Load saved theme color from localStorage on mount
  useEffect(() => {
    const savedColor = localStorage.getItem('themeColor');
    if (savedColor) {
      setThemeColor(savedColor);
    }
  }, [setThemeColor]);

  // Handle color selection
  const handleColorSelect = (colorHex) => {
    setThemeColor(colorHex);
    localStorage.setItem('themeColor', colorHex);
    setSnackbar({ open: true, message: 'Theme Color Updated', severity: 'success' });
  };

  // Popover handlers
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // Handle copying User ID
  const handleCopyUserId = () => {
    const userId = localStorage.getItem('email') || 'User ID';
    navigator.clipboard.writeText(userId);
    setSnackbar({ open: true, message: 'User ID Copied', severity: 'success' });
    handlePopoverClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'settings-popover' : undefined;

  // Handle closing the snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Main Content */}
      <Container
        sx={{
          mt: 4,
          flex: 1,
          textAlign: 'center',
          paddingLeft: { sm: '240px' },
          paddingRight: { sm: '240px' },
        }}
      >
        <Typography variant="h4" gutterBottom>
          Settings
        </Typography>

        {/* Theme Color Selection */}
        <Typography variant="h6" gutterBottom>
          Select Theme Color:
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: 2,
            mb: 4,
          }}
        >
          {predefinedColors.map((color) => (
            <Box
              key={color.name}
              onClick={() => handleColorSelect(color.hex)}
              sx={{
                width: 40,
                height: 40,
                borderRadius: '50%',
                backgroundColor: color.hex,
                border: themeColor === color.hex ? '3px solid #000' : '2px solid #ccc',
                cursor: 'pointer',
                boxShadow: themeColor === color.hex ? '0 0 5px rgba(0,0,0,0.5)' : 'none',
                transition: 'transform 0.2s, border 0.2s',
                '&:hover': {
                  transform: 'scale(1.1)',
                  border: '3px solid #000',
                },
              }}
              title={color.name}
              aria-label={`Select ${color.name} color`}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleColorSelect(color.hex);
              }}
            />
          ))}
        </Box>
      </Container>

      {/* Footer */}
      <Box
        sx={{
          width: isDrawerOpen ? 'calc(100% - 240px)' : 'calc(100% - 80px)', // Adjust width based on drawer state
          backgroundColor: 'background.paper',
          py: 2,
          px: 4,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed', // Fixed position to stretch across the bottom
          bottom: 0,
          left: isDrawerOpen ? '240px' : '80px', // Adjust left based on drawer state
          boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
          transition: 'left 0.3s, width 0.3s',
          zIndex: 1000,
        }}
      >
        {/* SptGPT Link */}
        <Typography
          variant="body1"
          component={Link}
          href="https://x.com/SptGPT"
          sx={{
            color: themeColor,
            textDecoration: 'none',
            fontWeight: 'bold',
            cursor: 'pointer',
            display: 'inline-block',
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <SptGPT themeColor={themeColor}>SptGPT</SptGPT>
        </Typography>

        {/* Help Icon Positioned to the Right */}
        <IconButton
          aria-describedby={id}
          onClick={handlePopoverOpen}
          sx={{
            color: themeColor,
            position: 'absolute',
            right: '16px', // Adjusted positioning
          }}
        >
          <FaQuestionCircle size={24} />
        </IconButton>

        {/* Popover for Help Options */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Box sx={{ p: 2, maxWidth: 300 }}>
            <Typography
              variant="body2"
              gutterBottom
              onClick={handleCopyUserId}
              sx={{
                cursor: 'pointer',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FaQuestionCircle style={{ marginRight: '8px', color: themeColor }} />
              {`User ID: ${localStorage.getItem('email') || 'User ID'}`}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Link href="/faq" onClick={handlePopoverClose} underline="hover">
                Help & FAQ
              </Link>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Link href="/terms" onClick={handlePopoverClose} underline="hover">
                Terms & Policies
              </Link>
            </Typography>
          </Box>
        </Popover>
      </Box>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        // Positioned just above the footer
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          mb: '70px', // Adjusted margin bottom to be above the fixed footer
        }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%', textAlign: 'center' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SettingsPage;
