// frontend/src/pages/ExplorePage.js

import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Grid,
  Typography,
  Button,
  Container,
  Box,
  InputBase,
  IconButton,
  Paper,
  ClickAwayListener,
  Collapse,
  Fab,
} from '@mui/material';
import { MdOutlineAdd } from 'react-icons/md';
import { IoSearchOutline, IoCloseOutline, IoArrowDown, IoArrowUp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeContext } from '../contexts/ThemeContext';
import { styled } from '@mui/material/styles';
import {
  collection,
  getDocs,
  query,
  orderBy,
} from 'firebase/firestore';
import { db } from '../firebase';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  marginLeft: theme.spacing(1),
  flex: 1,
  '&::placeholder': {
    color: '#aaa',
    opacity: 1,
  },
}));

const ExplorePage = () => {
  const theme = useTheme();
  const { themeColor } = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [events, setEvents] = useState([]);
  const [visibleCount, setVisibleCount] = useState(8); // Initially 2 rows (4 per row on md)
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const searchInputRef = useRef(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [imageIndices, setImageIndices] = useState({});
  const [showGoUp, setShowGoUp] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        // Query to fetch events ordered by creation time (newest first)
        const q = query(collection(db, 'events'), orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);
        const eventsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(eventsData.slice(0, 100)); // Limit to 100 events
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };
    fetchEvents();
  }, []);

  const filteredEvents = events.filter((event) =>
    event.experience.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleLoadMore = () => {
    const newCount = visibleCount + 8; // Load 2 more rows (assuming 4 per row)
    setVisibleCount(newCount > 100 ? 100 : newCount);
  };

  const handleSearchSubmit = (e) => e.preventDefault();

  const handleSearchIconClick = () => {
    if (!isMobile) {
      setSearchOpen((prev) => !prev);
      if (!searchOpen) {
        setTimeout(() => {
          searchInputRef.current?.focus();
          setCursorToEnd(searchInputRef.current);
        }, 300);
      }
    } else {
      searchInputRef.current?.focus();
    }
  };

  const handleInputChange = (e) => setSearchQuery(e.target.value);
  const handleSearchClickAway = () => {
    if (!isMobile) setSearchOpen(false);
  };

  const setCursorToEnd = (el) => {
    if (el) {
      const length = el.value.length;
      el.setSelectionRange(length, length);
      el.focus();
    }
  };

  // Handle image index changes
  const handlePrevImage = (eventId) => {
    setImageIndices((prev) => ({
      ...prev,
      [eventId]: prev[eventId] > 0 ? prev[eventId] - 1 : (events.find(e => e.id === eventId)?.images.length || 1) - 1,
    }));
  };

  const handleNextImage = (eventId) => {
    setImageIndices((prev) => ({
      ...prev,
      [eventId]: prev[eventId] < (events.find(e => e.id === eventId)?.images.length || 1) - 1 ? prev[eventId] + 1 : 0,
    }));
  };

  // Handle scroll to show "Go Up" button
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      // Adjust threshold as needed
      if (scrollTop > 800) {
        setShowGoUp(true);
      } else {
        setShowGoUp(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Custom scrollbar styling
  const scrollbarStyles = {
    // Hide scrollbar
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none', // IE and Edge
    'scrollbar-width': 'none', // Firefox
  };

  return (
    <Container
      sx={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'auto',
        minHeight: '100vh',
        ...scrollbarStyles,
      }}
    >
      {/* Search and Create Button */}
      <Box
        display="flex"
        justifyContent={isMobile ? 'center' : 'flex-end'}
        alignItems="center"
        sx={{
          width: '100%',
          marginBottom: '20px',
          flexDirection: 'row',
          position: 'relative',
          paddingRight: '16px',
        }}
      >
        {!isMobile ? (
          <ClickAwayListener onClickAway={handleSearchClickAway}>
            <Box display="flex" alignItems="center" position="relative">
              <IconButton
                onClick={handleSearchIconClick}
                sx={{
                  backgroundColor: themeColor,
                  color: 'white',
                  borderRadius: '50%',
                  padding: '8px',
                  mr: '6px',
                  '&:hover': { backgroundColor: theme.palette.primary.light },
                  transition: 'transform 0.3s, background-color 0.3s',
                  transform: searchOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                  boxShadow: `0px 4px 6px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.6)' : 'rgba(0,0,0,0.2)'}`,
                  width: '50px',
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                aria-label="search"
              >
                <IoSearchOutline size={24} />
              </IconButton>
              <Collapse
                in={searchOpen}
                orientation="vertical"
                timeout={{ enter: 300, exit: 300 }}
                sx={{
                  position: 'absolute',
                  right: '60px',
                  zIndex: 1,
                }}
              >
                <Paper
                  component="form"
                  onSubmit={handleSearchSubmit}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 8px',
                    borderRadius: '25px',
                    backgroundColor: theme.palette.background.paper,
                    width: '250px',
                    border: `1px solid ${themeColor}`,
                    height: '50px',
                    transition: 'all 0.3s ease-in-out',
                    overflow: 'hidden',
                    boxShadow: `0px 4px 6px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.6)' : 'rgba(0,0,0,0.1)'}`,
                  }}
                >
                  <StyledInputBase
                    placeholder={searchQuery === '' ? 'Search' : ''}
                    value={searchQuery}
                    inputProps={{ maxLength: 100, autoComplete: 'off' }}
                    onChange={handleInputChange}
                    inputRef={searchInputRef}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                  />
                  {searchQuery.length > 0 && (
                    <IconButton
                      sx={{ padding: '10px' }}
                      onClick={() => setSearchQuery('')}
                      aria-label="clear search"
                    >
                      <IoCloseOutline size={18} />
                    </IconButton>
                  )}
                </Paper>
              </Collapse>
            </Box>
          </ClickAwayListener>
        ) : (
          <Box
            component="form"
            onSubmit={handleSearchSubmit}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: '100%',
              maxWidth: '400px',
              marginBottom: { xs: '12px', sm: '0' },
            }}
          >
            <Paper
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '2px 10px',
                borderRadius: '25px',
                backgroundColor: '#f0f0f0',
                width: '100%',
                border: '1px solid #ccc',
                height: '40px',
                transition: 'border-color 0.3s',
                '&:focus-within': {
                  borderColor: themeColor,
                },
              }}
            >
              <IconButton
                sx={{
                  padding: '10px',
                  color: themeColor,
                }}
                onClick={handleSearchIconClick}
                aria-label="search"
              >
                <IoSearchOutline size={20} />
              </IconButton>
              <StyledInputBase
                placeholder={searchQuery === '' ? 'Search' : ''}
                value={searchQuery}
                inputProps={{ maxLength: 100, autoComplete: 'off' }}
                onChange={handleInputChange}
                inputRef={searchInputRef}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && searchQuery.trim() !== '') {
                    // Optionally, implement search functionality
                  }
                }}
              />
              {searchQuery.length > 0 && (
                <IconButton
                  sx={{ padding: '10px' }}
                  onClick={() => setSearchQuery('')}
                  aria-label="clear search"
                >
                  <IoCloseOutline size={18} />
                </IconButton>
              )}
            </Paper>
          </Box>
        )}
        {!isMobile && (
          <Button
            onClick={() => navigate('/create')}
            variant="contained"
            startIcon={<MdOutlineAdd size={24} />}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: 'white',
              borderRadius: '25px',
              padding: '10px 20px',
              textTransform: 'none',
              fontSize: '1rem',
              '&:hover': { backgroundColor: theme.palette.primary.light },
              height: '50px',
              minWidth: '120px',
              marginLeft: '16px',
              boxShadow: `0px 4px 6px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.6)' : 'rgba(0,0,0,0.2)'}`,
            }}
          >
            Create
          </Button>
        )}
      </Box>

      {/* Events Grid */}
      <Grid container spacing={2} sx={{ width: '100%', maxWidth: '1200px' }}>
        {filteredEvents.slice(0, visibleCount).map((event) => (
          <Grid item xs={12} sm={6} md={3} key={event.id || event._id}>
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: '20px', // Rounded box around the entire card
                padding: '12px',
                textAlign: 'center',
                cursor: 'pointer',
                transition: 'transform 0.3s, box-shadow 0.3s',
                border: `1px solid ${theme.palette.divider}`, // Always visible border
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                height: '100%',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: `0px 8px 16px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.7)' : themeColor + '80'}`, // 80 for 50% opacity
                },
              }}
              onClick={() => navigate(`/events/${event.id || event._id}`)}
              tabIndex={0} // Make the entire card focusable
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  navigate(`/events/${event.id || event._id}`);
                }
              }}
            >
              <Box sx={{ position: 'relative' }}>
                {event.images?.[0] && (
                  <Box
                    component="img"
                    src={event.images[imageIndices[event.id] || 0]}
                    alt={event.experience}
                    sx={{
                      width: '100%',
                      height: '140px', // Reduced height
                      objectFit: 'cover',
                      borderRadius: '10px', // Less rounded corners
                      marginBottom: '8px',
                    }}
                  />
                )}
                {event.images && event.images.length > 1 && (
                  <>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePrevImage(event.id);
                      }}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '10px',
                        transform: 'translateY(-50%)',
                        backgroundColor: 'rgba(255,255,255,0.7)',
                        '&:hover': { backgroundColor: 'rgba(255,255,255,0.9)' },
                      }}
                      size="small"
                      aria-label="previous image"
                    >
                      <ArrowBackIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleNextImage(event.id);
                      }}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        right: '10px',
                        transform: 'translateY(-50%)',
                        backgroundColor: 'rgba(255,255,255,0.7)',
                        '&:hover': { backgroundColor: 'rgba(255,255,255,0.9)' },
                      }}
                      size="small"
                      aria-label="next image"
                    >
                      <ArrowForwardIcon fontSize="small" />
                    </IconButton>
                  </>
                )}
              </Box>

              {/* Dot Indicators Positioned at Bottom of Image */}
              {event.images && event.images.length > 1 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    bottom: '12px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                >
                  {event.images.map((_, idx) => (
                    <Box
                      key={idx}
                      sx={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: (imageIndices[event.id] || 0) === idx ? 'white' : 'gray',
                        margin: '0 4px',
                        border: '1px solid #fff',
                      }}
                    />
                  ))}
                </Box>
              )}

              {/* Event Information */}
              <Box sx={{ mt: 1, flexGrow: 1 }}>
                <Typography variant="h6">{event.experience}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {event.locations?.join(', ') || 'Location(s) not available'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {event.dateTime
                    ? `${new Date(event.dateTime.toDate()).toLocaleDateString()} | ${new Date(event.dateTime.toDate()).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`
                    : 'Date and time not available'}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Load More Button */}
      {visibleCount < Math.min(filteredEvents.length, 100) && (
        <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
          <IconButton
            onClick={handleLoadMore}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: 'white',
              '&:hover': { backgroundColor: theme.palette.primary.light },
              boxShadow: `0px 4px 6px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.6)' : theme.palette.primary.main + '80'}`,
            }}
            aria-label="load more"
          >
            <IoArrowDown size={24} />
          </IconButton>
        </Box>
      )}

      {/* Go Up Button */}
      {showGoUp && (
        <Fab
          color="primary"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          sx={{
            position: 'fixed',
            bottom: '30px',
            right: '30px',
            zIndex: 1000,
            boxShadow: `0px 4px 6px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.6)' : theme.palette.primary.main + '80'}`,
          }}
          aria-label="go up"
        >
          <IoArrowUp size={24} />
        </Fab>
      )}
    </Container>
  );
};

export default ExplorePage;