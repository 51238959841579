import React, { useContext, useEffect, useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
} from '@mui/material';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import { GoHomeFill, GoHome } from 'react-icons/go';
import { IoSearchOutline, IoSearchSharp } from 'react-icons/io5';
import { MdOutlineExplore, MdExplore } from 'react-icons/md';
import { IoIosNotificationsOutline, IoIosNotifications } from 'react-icons/io';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser as faUserSolid } from '@fortawesome/free-solid-svg-icons';
import { faUser as faUserRegular } from '@fortawesome/free-regular-svg-icons';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import { useNavigate, useLocation } from 'react-router-dom';
import { ThemeContext } from '../contexts/ThemeContext';
import useMediaQuery from '@mui/material/useMediaQuery';

const LeftNavBar = ({ isOpen, toggleDrawer }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { themeColor } = useContext(ThemeContext);
  const isMobile = useMediaQuery('(max-width:600px)');

  if (isMobile) return null;

  const drawerWidth = isOpen ? 240 : 80;
  const [tooltipTitle, setTooltipTitle] = useState(
    isOpen ? 'Close navigation menu' : 'Open navigation menu'
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setTooltipTitle(isOpen ? 'Close navigation menu' : 'Open navigation menu');
    }, 200);
    return () => clearTimeout(timer);
  }, [isOpen]);

  const navigationItems = [
    { label: 'Home', path: '/home', icons: [<GoHome size={30} />, <GoHomeFill size={30} />] },
    { label: 'Search', path: '/search', icons: [<IoSearchOutline size={30} />, <IoSearchSharp size={30} />] },
    { label: 'Explore', path: '/explore', icons: [<MdOutlineExplore size={30} />, <MdExplore size={30} />] },
    { label: 'Notifications', path: '/notifications', icons: [<IoIosNotificationsOutline size={30} />, <IoIosNotifications size={30} />] },
    { label: 'Bookmarks', path: '/bookmarks', icons: [<BookmarksOutlinedIcon sx={{ fontSize: 30 }} />, <BookmarksIcon sx={{ fontSize: 30 }} />] },
    { label: 'Upgrade', path: '/upgrade', icons: [<AutoAwesomeOutlinedIcon sx={{ fontSize: 30 }} />, <AutoAwesomeIcon sx={{ fontSize: 30 }} />] },
    { label: 'Profile', path: '/profile', icons: [
      <FontAwesomeIcon icon={faUserRegular} style={{ fontSize: '30px' }} />,
      <FontAwesomeIcon icon={faUserSolid} style={{ fontSize: '30px' }} />
    ] },
    { label: 'Settings', path: '/settings', icons: [<SettingsSuggestOutlinedIcon sx={{ fontSize: 34 }} />, <SettingsSuggestIcon sx={{ fontSize: 34 }} />] },
  ];

  const isSelected = (path) => location.pathname === path || (path === '/home' && location.pathname === '/');

  return (
    <Drawer
      variant="permanent"
      open={isOpen}
      PaperProps={{
        sx: {
          width: drawerWidth,
          backgroundColor: themeColor,
          color: 'white',
          overflowX: 'hidden',
          transition: 'width 0.3s',
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '20px',
        },
      }}
    >
      <List disablePadding>
        <ListItem
          button
          onClick={toggleDrawer}
          sx={{
            justifyContent: 'center',
            paddingY: 1,
            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
          }}
        >
          <Tooltip title={tooltipTitle} placement="right" arrow>
            <IconButton
              sx={{
                color: 'white',
                fontSize: '1.5rem',
                marginLeft: isOpen ? '10px' : '0',
              }}
              aria-label={isOpen ? 'Close navigation menu' : 'Open navigation menu'}
            >
              <BlurOnIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </ListItem>
        {navigationItems.map(({ label, path, icons }) => {
          const selected = isSelected(path);
          return (
            <ListItem
              key={path}
              button
              onClick={() => navigate(path)}
              sx={{
                justifyContent: isOpen ? 'flex-start' : 'center',
                alignItems: 'center',
                paddingY: 1,
                '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
                backgroundColor: selected ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
              }}
            >
              <Tooltip title={!isOpen ? label : ''} placement="right" arrow>
                <IconButton
                  sx={{
                    color: 'white',
                    fontSize: '1.5rem',
                    marginLeft: isOpen ? '10px' : '0',
                  }}
                  aria-label={label}
                >
                  {selected ? icons[1] : icons[0]}
                </IconButton>
              </Tooltip>
              {isOpen && (
                <ListItemText
                  primary={label}
                  sx={{
                    color: 'white',
                    marginLeft: '10px',
                    fontWeight: selected ? 'bold' : 'normal',
                  }}
                />
              )}
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
};

export default LeftNavBar;