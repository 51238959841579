// frontend/src/pages/ProfilePage.js

import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Button, Box, Avatar, CircularProgress } from '@mui/material';
import { ThemeContext } from '../contexts/ThemeContext';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

const ProfilePage = () => {
  const { themeColor } = useContext(ThemeContext);
  const navigate = useNavigate();
  const user = auth.currentUser;
  const [role, setRole] = useState('User');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserRole = async () => {
      if (user) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setRole(userData.role || 'User'); // Default to 'User' if role is undefined
          } else {
            console.log('No such user document!');
            setRole('User'); // Default role
          }
        } catch (error) {
          console.error('Error fetching user role:', error);
          setRole('User'); // Default role in case of error
        }
      }
      setLoading(false);
    };

    fetchUserRole();
  }, [user]);

  // Handle logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login'); // Redirect to login page
    } catch (error) {
      console.error('Error signing out:', error);
      alert('Error signing out.');
    }
  };

  // Handle upgrade
  const handleUpgrade = () => {
    navigate('/upgrade');
  };

  if (loading) {
    return (
      <Container
        maxWidth="sm"
        sx={{
          mt: 4,
          mb: 6,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        mt: 4,
        mb: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4" gutterBottom textAlign="center">
        Profile
      </Typography>
      <Avatar
        src={user?.photoURL || ''}
        alt={user?.displayName || 'User'}
        sx={{ width: 100, height: 100, mb: 2 }}
      />
      <Typography variant="h6" gutterBottom>
        {user?.displayName || 'No Name Provided'}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Email: {user?.email || 'No Email Provided'}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Role: {role}
      </Typography>
      {/* Add more user-specific information here */}
      <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
        {role === 'User' && (
          <Button
            variant="contained"
            onClick={handleUpgrade}
            sx={{
              backgroundColor: themeColor,
              color: '#fff',
              '&:hover': { backgroundColor: themeColor },
            }}
          >
            Upgrade Profile
          </Button>
        )}
        <Button
          variant="contained"
          onClick={handleLogout}
          sx={{
            backgroundColor: themeColor,
            color: '#fff',
            '&:hover': { backgroundColor: themeColor },
          }}
        >
          Logout
        </Button>
      </Box>
    </Container>
  );
};

export default ProfilePage;